html,
body,
#root {
  margin: 0;
  width: 100vw;
  height: 100vh;
  border-radius: 0;
  font-family: 'Roboto', sans-serif;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Light.eot');
  src: url('./assets/fonts/Roboto/Roboto-Light.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Roboto/Roboto-Light.woff2') format('woff2'),
    url('./assets/fonts/Roboto/Roboto-Light.woff') format('woff'),
    url('./assets/fonts/Roboto/Roboto-Light.ttf') format('truetype'),
    url('./assets/fonts/Roboto/Roboto-Light.svg#Roboto-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Regular.eot');
  src: url('./assets/fonts/Roboto/Roboto-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
    url('./assets/fonts/Roboto/Roboto-Regular.woff') format('woff'),
    url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype'),
    url('./assets/fonts/Roboto/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Medium.eot');
  src: url('./assets/fonts/Roboto/Roboto-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
    url('./assets/fonts/Roboto/Roboto-Medium.woff') format('woff'),
    url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype'),
    url('./assets/fonts/Roboto/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

#root {
  display: flex;
  justify-content: center;
  border-radius: 0;
}

.car-slider {
  height: 76px;
  width: 98%;
}

.car-slider .slick-slide img {
  height: 76px;
  width: 76px;
}
